import React from 'react'
import Error from 'next/error'
import { getDisplayName } from '../utils/boiler'
import { LocaleProvider } from '../contexts/LocaleContext'
import moment from "moment"

const Hoc = WrappedPage => {
  const WithLocale = props => {
    const { locale } = props
    moment.locale(locale)
    if (!locale) {
      return <Error statusCode={404} />
    }
    return (
      <LocaleProvider lang={locale}>
        <WrappedPage {...props} />
      </LocaleProvider>
    )
  }
  WithLocale.displayName = `withLang(${getDisplayName(WrappedPage)})`

  return WithLocale
}

export default Hoc