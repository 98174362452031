import React from 'react'

const Stars = props => {
    const starsArray = []
    const stars = Math.ceil(props.stars)
    for (let i = 1; i <= 5; i++) {
        i <= stars ?
          starsArray.push(
            <i key={i} className={`fa ${props.size ? `fa-`+props.size : ""} fa-star ${props.color ? props.color : "text-warning"}`} />
          )
          :
          starsArray.push(
            <i key={i} className={`fa fa-star ${props.size ? `fa-`+props.size : ""} text-gray-300`} />
          )

    }
    return starsArray
}

export default Stars