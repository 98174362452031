import React from 'react'

import ReactIdSwiper from 'react-id-swiper'
import CardTestimonial from '../components/CardTestimonial'

export default props => {
    const data = props.data
    const params = {

        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        roundLengths: false,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 0
            },
            565: {
                slidesPerView: 1
            }
        },
        pagination: {
            el: `.swiper-pagination`,
            clickable: true,
            dynamicBullets: true,
        },
        autoplay: {
            delay: 4500,
            disableOnInteraction: true
        },
        // pagination: {
        //     el: `.swiper-pagination`,
        //     clickable: true,
        //     dynamicBullets: true
        // },
        containerClass: `swiper-container pt-2 pb-5`,
    }
    return (
        <ReactIdSwiper {...params}>
            {data.map((item, index) =>
                <div key={index} className="px-3" style={{ height: "auto", minHeight: "100%" }}>
                    <CardTestimonial data={item} />
                </div>
            )}
        </ReactIdSwiper>
    )
}