import React from 'react'
import Link from 'next/link'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'

import Swiper from './Swiper'
import useTranslation from '../hooks/UseTranslation'
import CardListing2 from './CardListing2'
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination])

export default props => {
  const { listings, isOnline } = props
  const { t } = useTranslation()
  if (!listings)
    return <p></p>

  return (
    <section className={`py-6 ${props.greyBackground ? 'bg-gray-100' : ''}`}>
      <Container>
        <Col>
          <Row className="mb-0">
            <Col md="12" className="text-center">
              <p className="subtitle text-secondary">{t("last_minute_subtitle")}</p>
              <h2>{isOnline ? t("last_minute_title_online") : t("last_minute_title")}</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col
              md="12"
              className="d-lg-flex align-items-right justify-content-end"
            >
              <Link href="/classes">
                <a className="text-muted text-sm">
                  {t("last_minute_button")}
                  <i className="fas fa-angle-double-right ml-2" />
                </a>
              </Link>
            </Col>
          </Row>
        </Col>
        <Swiper
          data={listings}
          className="swiper-container-mx-negative pt-3 pb-5"
          perView={1}
          spaceBetween={20}
          roundLengths
          md={2}
          lg={3}
          xl={3}
          autoplay
          delay={4000}
          cards
          loop
        />
      </Container>
    </section>
  )
}