import React from 'react'
import { getAxiosParams, getListings } from '../utils/client'
import moment from 'moment'

import Link from 'next/link'
import { useRouter } from 'next/router'
import Bounce from 'react-reveal/Bounce';

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody
} from 'reactstrap'

import ReactHtmlParser from 'react-html-parser'
import Discover from '../components/Discover'

import SwiperTestimonial from '../components/SwiperTestimonial'

import data from '../data/index.json'
import useTranslation from '../hooks/UseTranslation'
import WithLocale from '../hocs/WithLocale'
import { UserContext } from '../contexts/UserContext'
import { isMobile } from 'react-device-detect';
import Fab from "../components/Fab"
import { HeaderCollapsedContext } from '../contexts/HeaderCollapsedContext'
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { ListingsApi } from 'typescript-axios'
import LastMinute from '../components/LastMinute'

// FIXME: remove this SH1T react id swiper package
SwiperCore.use([Pagination, Autoplay])


export async function getStaticProps({ locale, defaultLocale }) {
    return {
        revalidate: 60,
        props: {
            nav: {
                dark: true,
                classes: "shadow",
                color: "transparent",
            },
            noPaddingTop: true,
            title: null,
            locale,
            defaultLocale,
        },
    }
}


const Page = props => {
    const { t } = useTranslation()
    const [selection, setSelection] = React.useState(null)
    const [listings, setListings] = React.useState(null)
    const { setNavBarColor, setNavBarLight, setNavBarDark } = React.useContext(HeaderCollapsedContext)
    const { defaultLocation } = React.useContext(UserContext)
    const bgRef = React.useRef()

    React.useEffect(
        () => {
            setNavBarDark(true)
            setNavBarLight(false)
            if (!isMobile)
                setNavBarColor("transparent")
        }, []
    )

    React.useEffect(() => {
        const handleScroll = () => {
            const th = isMobile ? 0 : bgRef.current?.offsetHeight * 0.6
            const show = window.scrollY > th
            if (show) {
                setNavBarColor("white")
                setNavBarLight(true)
                setNavBarDark(false)
            } else {
                setNavBarColor("transparent")
                setNavBarLight(false)
                setNavBarDark(true)
            }
        }

        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const router = useRouter()
    if (typeof window !== 'undefined' && selection) {
        // useRouter().push({ pathname: '/classes', query: selection })
        router.push({
            pathname: '/gurus',
            // query: selection,
        }) // fixme 
    }

    React.useEffect(
        () => {
            if (defaultLocation) {
                getListings({
                    isOnline: true, // FIXME: this is needed otherwise it will only fetch physical
                    dateStart: moment(),
                    dateEnd: moment().add(1, 'w'),
                    location: defaultLocation.id
                }).then(setListings)
            }
        }, [defaultLocation]
    )

    return (
        <React.Fragment>
            <Fab onClick={() => router.push("/guru-get-started")}>
                {isMobile ? t("index_is_guru_fab_mobile") : t("index_is_guru_fab_desktop")}
            </Fab>
            <section ref={bgRef} className="hero-home vh-100" style={{ backgroundImage: `url(https://gurubay-front-res.s3.fr-par.scw.cloud/index.png)` }} >
                <Container className="py-6 py-md-7 text-white z-index-20">
                    <div className="d-none d-md-block" style={{ height: "200px" }} />
                    <Row className="mb-4">
                        <Col xl="10" lg="12" >
                            <div className="p-4 p-md-0">
                                <h1 className="mb-0 font-weight-bold text-shadow">
                                    {ReactHtmlParser(isMobile ? t("index_hero_title_mobile") : t("index_hero_title"))}
                                </h1>
                                <h4 className="font-weight-normal text-shadow">{ReactHtmlParser(t("index_hero_subtitle"))}</h4>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center justify-content-lg-start justify-content-xl-start">
                        <Link href="/classes" as="/classes">
                            <Button className="mt-5 btn-lg shadow" color="primary">
                                {t("index_cta")}
                            </Button>
                        </Link>
                    </div>
                </Container>
            </section>
            <LastMinute listings={listings} />
            {
                <section className="py-6 bg-gray-100">
                    <Container>
                        <div className="text-center pb-lg-4">
                            <p className="subtitle text-secondary">{t("index_topblocks_title")}</p>
                            <h2 className="mb-5">{t("index_topblocks_subtitle")}</h2>
                        </div>
                        <Row>
                            {data.topBlocks.blocks.map(block =>
                                <Col
                                    key={t(block.title)}
                                    lg="4"
                                    className="mb-3 mb-lg-0 text-center"
                                >
                                    <div className="px-0 px-lg-3">
                                        <div className="icon-rounded bg-primary-light mb-3">
                                            <svg className="svg-icon text-primary w-2rem h-2rem">
                                                <use xlinkHref={`content/svg/orion-svg-sprite.svg${block.icon}`}> </use>
                                            </svg>
                                        </div>
                                        <h3 className="h5">{t(block.title)}</h3>
                                        <div className="text-muted" dangerouslySetInnerHTML={{ __html: t(block.content) }} />
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </section>
            }
            <section className="py-6 position-relative" >
                <img src="https://gurubay-front-res.s3.fr-par.scw.cloud/empty-bg.png" alt="" className="bg-image" />
                <Container>
                    <Row>
                        <Col lg="2" />
                        <Col lg="8">
                            <div className="bg-white rounded-lg shadow p-5">
                                <h5 className="subtitle text-secondary">{t("index_quick_desc_title")}</h5>
                                {/* <h2 className="mb-5">{t("index_quick_desc_title")}</h2> */}
                                <ul className="fa-ul my-4">
                                    <li className="mb-3 lead">
                                        <span className="fa-li text-primary"><strong>1</strong></span>
                                        {t("index_quick_desc_content0")}
                                    </li>
                                    <li className="mb-3 lead">
                                        <span className="fa-li text-primary"><strong>2</strong></span>
                                        {t("index_quick_desc_content1")}
                                    </li>
                                    <li className="lead">
                                        <span className="fa-li text-primary"><strong>3</strong></span>
                                        {t("index_quick_desc_content2")}
                                    </li>
                                </ul>
                            </div>

                        </Col>
                        <Col lg="2" />
                    </Row>
                </Container>
            </section>
            {
                // this is buggy atm: shows private classes
                // listings && listings.length > 0 && <LastMinute greyBackground isOnline={isLastMinuteOnline} listings={listings} />
            }
            {
                <section className="py-6">
                    <Container>
                        <Discover
                            title={data.hesitation.title}
                            subtitle={data.hesitation.subtitle}
                            blocks={data.hesitation.blocks}
                        />
                    </Container>
                </section>
            }
            {
                <section className="py-6 position-relative gradient-light-overlay">
                    <img src={`https://gurubay-front-res.s3.fr-par.scw.cloud/gradient.jpg`} alt="" className="bg-image" />
                    <Container>
                        <div className="overlay-content text-white py-lg-5">
                            <Row>
                                <Col md="8" xs="12" className="p-lg-5 px-5 mb-5 mb-md-0">
                                    <h3 className="display-3 font-weight-bold text-shadow mb-4">
                                        {ReactHtmlParser(t("index_jumbotron_title"))}
                                    </h3>
                                    <Link href={data.jumbotron.link}>
                                        <Button color="primary" className="btn-lg shadow">
                                            {t("index_jumbotron_button")}
                                        </Button>
                                    </Link>
                                </Col>
                                <Col lg="4" md="4" xs="12" className="px-5 px-md-0 ">
                                    <Bounce right>
                                        <Card className="border-0 shadow">
                                            <CardBody className="p-4">
                                                <img className="img-fluid" src="https://gurubay-front-res.s3.fr-par.scw.cloud/get-started-dashboard.jpg" />
                                            </CardBody>
                                        </Card>
                                    </Bounce>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            }
            {
                <section className="py-6 position-relative">
                    <Container>
                        <div className="text-center">
                            <p className="subtitle text-secondary">
                                {t("index_testimonials_title")}
                            </p>
                            <h2 className="mb-5">
                                {t("index_testimonials_subtitle")}
                            </h2>
                        </div>
                        <SwiperTestimonial pagination={true} t={t} data={data.testimonials.swiperItems} />
                    </Container>
                </section>
            }
            {/* <Instagram /> */}
        </React.Fragment>
    )
}

export default WithLocale(Page)