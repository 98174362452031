import useTranslation from '../hooks/UseTranslation'
import { firstLetter, getRecurrenceString} from '../utils/boiler'

const ListingIcons = props => {
    const { t } = useTranslation()
    const { listing } = props
    return (
        (listing.audiences || listing.is_private) &&
        <ul className="list-inline mb-4">
            {listing.audiences.map((audience, i) =>
                <li
                    key={audience.name}
                    className="list-inline-item mr-3"
                >
                    <div className="d-flex align-items-center">
                        <i className="fa fa-chevron-right text-secondary mr-2" />
                        <span>{audience.name}</span>
                    </div>
                </li>
            )}
            {listing.is_private && <li
                key={"listingPrivate"}
                className="list-inline-item mr-3"
            >
                <div className="d-flex align-items-center">
                    <i className={`far  fa-eye-slash mr-1`} /> <span>{firstLetter(t("private"))}</span>
                </div>
            </li>}
            {listing.frequency && <li
                key="listingFrequency"
                className="list-inline-item mr-3"
            >
                <div className="d-flex align-items-center">
                    <i className="fa fa-sync-alt mr-1" /> <span>{firstLetter(getRecurrenceString(listing.frequency, t))}</span>
                </div>
            </li>
            }
        </ul>
    )
}

export default ListingIcons