import React from 'react'

import {
    Card
} from 'reactstrap'
import useTranslation from '../hooks/UseTranslation'
import Link from "next/link"
import ReactHtmlParser from 'react-html-parser';

export default props => {
    const data = props.data
    const { t } = useTranslation()
    return (
        <Card className="testimonial hover-animate rounded-lg shadow border-0" style={{ minHeight: "100%" }}>
            <Link href={"/users/[slug]"} as={data.link} passHref>
                <a target="_blank" rel="noreferrer">
                    <div className="testimonial-avatar">
                        <img src={data.avatar} alt={data.title} className="img-fluid" />
                    </div>
                </a>
            </Link>
            <div className="text">
                <div className="testimonial-quote">
                    <i className="fas fa-quote-right" />
                </div>
                <p className="testimonial-text">
                    {ReactHtmlParser(t(data.content))}
                </p>

                <Link href={"/users/[slug]"} as={data.link} passHref>
                    <a target="_blank" rel="noreferrer">
                        <strong>{t(data.title)}</strong>
                    </a>
                </Link>
                <a href={data.social} target="_blank" className="text-hover-primary ml-2 text-reset">
                    <i className={`fab fa-instagram`} />
                </a>
            </div>
        </Card>
    )
}