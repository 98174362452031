import React from "react"
import { isMobile } from "react-device-detect"
import { Button } from "reactstrap"
import { HideOn, HideScroll } from "react-hide-on-scroll"
import { isBrowser } from "../utils/boiler"

const Fab = props => {
    if (isBrowser()) {
        const btn = <Button
            onClick={props.onClick ?? (() => null)}
            color="primary"
            className={`rounded-xl`}
            style={{
                position: "fixed",
                bottom: "2rem",
                right: "2rem",
                zIndex: 1000,
            }}>
            <span className="btn text-white">
                {props.children}
            </span>
        </Button>
        if (isMobile)
            return <HideScroll variant="up">
                <HideOn inverse={true} showOnPageInit={false} height={100}>
                    {btn}
                </HideOn>
            </HideScroll>
        else
            return btn
    } else {
        return <div />
    }
}

export default Fab