import React from 'react'
import Link from 'next/link'

import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardSubtitle,
    CardText,
    Media, Badge, Row, Col
} from 'reactstrap'
import Stars from './Stars'
import useTranslation from '../hooks/UseTranslation'
import { firstLetter, getCurrencySign, getFirstClassDiscountBadge, getParticipantsBadge, substrWithDots } from '../utils/boiler'
import ListingIcons from './ListingIcons'
import { getFormattedDateAndRange } from '../utils/datetime'
import Image from '../components/CustomImage'
import moment from 'moment'

const CardListing2 = props => {
    const { t } = useTranslation()
    const listing = props.data
    const { showAvatar } = props
    const { owner } = listing

    return <Card className="border-0 shadow h-100">
        <Link href={"/classes/[slug]"} as={`/classes/${listing.slug}`}>
            <a className="tile-link" target="_blank" rel="noreferrer" />
        </Link>
        <div className="card-img-top gradient-overlay" style={{ minHeight: '200px', maxHeight: '200px' }}>
            <Image
                src={listing.cover ?? `/content/img/activities/default-${listing.activity.id}.jpg`}
                alt={listing.title}
                layout="fill"
                loading={"eager"}
                objectFit="cover"
                className="img-fluid"
                style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                }}
            />
            <div className="card-img-overlay-bottom z-index-20">
                <Media className="text-white text-sm align-items-center">
                    <div className="avatar avatar-border-white mr-2 overflow-hidden">
                        <Image
                            loading={"eager"}
                            objectFit="cover"
                            layout="fill"
                            src={owner.avatar ?? '/content/img/avatar/dummy-user.jpg'} alt={owner.firstname} />
                    </div>
                    <Media body>
                        <strong>{owner.firstname}</strong>
                    </Media>
                    {(owner.n_reviews > 5 || owner.rating > 3) &&
                        <p className="flex-shrink-1 mb-0 card-stars text-right">
                            <Stars stars={owner.rating} />
                        </p>
                    }
                </Media>
            </div>
            <div className="card-img-overlay-top text-right">
                {getParticipantsBadge(listing, t)}
                {/* <Badge
                color="primary-light"
                style={{ marginRight: '5px' }}><i className="fas fa-users fa-fw text-primary mr-2" />
                {`${listing.num_participants}/${listing.max_participants}`}
            </Badge> */}
                <Badge
                    color="secondary-light"
                    style={{ marginRight: '5px' }}>{firstLetter(listing.activity.name)}
                </Badge>
            </div>
        </div>
        <CardBody>
            <h5 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                {substrWithDots(listing.title, 37)}
            </h5>
            {/* <p className="subtitle font-weight-normal text-sm mb-2">{listing.is_online ? t("online") : listing.ppty.property_type.name}</p> */}
            <div className="subtitle font-weight-normal text-sm mb-4">
                <div className="mr-2">
                    <i className={`fa ${listing.is_online ? "fa-globe" : "fa-map-marker-alt"} text-muted mr-2`} />
                    {listing.is_online ? firstLetter(t("online")) : listing.ppty.location.city}
                </div>
                <div className="mr-2">
                    <i className={`fa fa-clock text-muted mr-2 mb-4`} />
                    {moment(listing.datetime_start).local().format("D MMM H:mm")}
                </div>
            </div>
            <div className="text-muted d-flex justify-content-between align-items-end" style={{ position: "absolute", bottom: "1.25rem", left: 20, right: 20 }}>
                {(listing.is_offers_apply && owner.first_class_discount > 0) ? getFirstClassDiscountBadge(listing.is_offers_apply ? owner.first_class_discount : 0, t) : <div />}
                <span className="h4 text-primary">
                    {/* fixme: currency harcoded */}
                    {`${listing.price} ${getCurrencySign("EUR")}`}
                </span>
            </div>
        </CardBody>
    </Card>
}

export default CardListing2;
