import moment from 'moment'
import React from 'react'
import { useRouter } from 'next/dist/client/router'
// import { isLocale } from '../translations/types'


export const LocaleContext = React.createContext({
    locale: 'en',
    setLocale: () => null,
})

export const LocaleProvider = ({ lang, children }) => {
    const [locale, setLocale] = React.useState(lang)
    const [defaultLocale, setDefaultLocale] = React.useState('en')
    const router = useRouter()

    // store the preference
    React.useEffect(() => {
        if (locale !== localStorage.getItem('locale')) {
            localStorage.setItem('locale', locale)
        }
        if(locale == "fr")
            moment.locale("fr-FR")
        else
            moment.locale("en-US")
    }, [locale])

    // sync locale value on client-side route changes
    React.useEffect(() => {
        setLocale(router.locale)
    }, [router.locale])
    return <LocaleContext.Provider value={{ locale, setLocale, defaultLocale }}>{children}</LocaleContext.Provider>
}